<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:33:38
 * @LastEditTime: 2022-03-21 11:22:26
-->
<template>
  <div class="dashboard">
    <div class="dashboard-inner">
      <div class="left">
        <!-- <img src="@/assets/images/phone.png" alt="" /> -->
      </div>
      <div class="right">
        <div class="top">
          <img src="@/assets/images/ipick.png" alt="" />
        </div>
        <div class="middle">同行圈致力于连接职场需求，成为美好职场小帮手。学习成长、求职招聘、兼职副业、商机合作、职场问答、投资融资，尽在同行圈。</div>
        <div class="bottom">
          <div class="bottom-item">
            <div class="bottom-item-top">
              <img src="@/assets/images/ios.png" alt="" />
              <div class="">App Store</div>
            </div>
            <div class="bottom-item-code">
              <img src="@/assets/images/iosCode.png" alt="" />
            </div>
          </div>
          <div class="bottom-item">
            <div class="bottom-item-top">
              <img src="@/assets/images/android.png" alt="" />
              <div class="">Android</div>
            </div>
            <div class="bottom-item-code">
              <img src="@/assets/images/iosCode.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  height: 100%;
  padding-top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dashboard-inner {
    display: flex;
    height: 100%;
    .left {
      width: 319px;
      height: 644px;
      margin-right: 100px;
      background-image: url("../../assets/images/phone.png");
      background-repeat: no-repeat;
      background-size: 319px auto;
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }
    .right {
      display: flex;
      flex-direction: column;
      margin-top: 140px;
      .top {
        width: 276px;
        height: 78px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .middle {
        width: 700px;
        word-break: break-all;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .bottom {
        display: flex;
        .bottom-item {
          width: 200px;
          height: 54px;
          background: #ffffff;
          border-radius: 24px;
          font-size: 20px;
          font-family: PingFangSC-Regular;
          color: #000000;
          margin-right: 32px;
          overflow: hidden;
          cursor: pointer;
          .bottom-item-top {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;
            img {
              width: 34px;
              height: 34px;
              margin-right: 10px;
            }
          }
          .bottom-item-code {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            img {
              width: 168px;
              height: 168px;
            }
          }
        }
        .bottom-item:hover {
          height: 100%;
        }
      }
    }
  }
}
</style>
